<template>
  <div style="z-index: 200">
    <v-form v-model="valid">
      <block v-if="loading"></block>
      <v-row class="mt-0">
        <v-col cols="12">
          <div class="shadow row ma-0 py-4 box pl-4 pr-4">
            <v-row class="mt-5">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" v-if="this.orderDetails != null">
                    <v-alert type="warning"
                      >Orden de compra sin procesar.</v-alert
                    >
                  </v-col>
                  <v-col cols="6" md="3">
                    <date-picker
                      label="Fecha de Orden de Compra"
                      v-model="purchaseorderdate"
                      @dateSelected="(param) => (purchaseorderdate = param)"
                    ></date-picker>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field
                      outlined
                      dense
                      v-model="orderid"
                      label="Número de Orden de Compra"
                      :readonly="readonly"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field
                      outlined
                      dense
                      v-model="this.data.ID_Provider"
                      label="ID del Proveedor"
                      :readonly="readonly"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3" >
                    <v-text-field
                   
                      outlined
                      dense
                      v-model="this.data.ProviderName"
                      label="Nombre del Proveedor"
                      :readonly="readonly"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="6" >
                <div
                  :class="showBgcolorNotes"
                  @click="showComment = !showComment"
                  style="margin-bottom: 10px"
                >
                  <center>Notas internas de la orden de compra</center>
                </div>
              </v-col>

              <v-col cols="12" sm="6"  >
                <h3 
                  class="tabs"
                  style="text-align: center;margin-bottom: 10px"
                  @click="showInfoPurchase = !showInfoPurchase"
                >
                  Datos de la factura del proveedor
                </h3>
              </v-col>

              <v-dialog v-model="showComment" scrollable max-width="1000">
                <v-card height="inherit">
                  <v-card-title class="text-h5"
                    >Notas internas de la orden de compra</v-card-title
                  >
                  <v-card-text>
                    <quill-editor
                      :content="header.purchaseorderinternalnote"
                      @change="onEditorChange($event)"
                    ></quill-editor>
                  </v-card-text>
                  <v-card-actions>
                    <v-col class="text-right">
                      <v-btn
                        @click="showComment = !showComment"
                        class="btndisable"
                      >
                        Continuar
                      </v-btn>
                    </v-col>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="showInfoPurchase" max-width="1000">
                <v-card height="inherit">
                  <v-card-title class="text-h5"
                    >Datos de la factura</v-card-title
                  >
                  <v-card-text>
                    <v-row class="mt-6 ml-2 mr-2 mb-6">
                      <v-col cols="12" md="3">
                        <center>
                          <v-img
                            :src="url"
                            width="100px"
                            height="100px"
                            class="preview-avatar"
                          ></v-img>
                        </center>
                        <v-file-input
                          @change="Preview_image"
                          v-model="image"
                          label="Imágen de Factura"
                          prepend-icon="mdi-camera"
                          placeholder="Imágen de Factura"
                          :rules="[maxFileSize]"
                        >
                        </v-file-input>
                      </v-col>

                      <v-col cols="12" md="9">
                        <v-row>
                          <v-col cols="12" md="6">
                            <date-picker
                              ref="inputdatepicker"
                              label="Fecha de la Factura"
                              v-model="purchaseinvoicedate"
                              @dateSelected="
                                (param) => (purchaseinvoicedate = param)
                              "
                            ></date-picker>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-select
                              outlined
                              dense
                              :items="catalogTypedocument"
                              attach
                              label="Tipo de Factura"
                              v-model="header.purchaseordertype"
                              @change="addValidToTypeInvoice(true)"
                              required
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-autocomplete
                              outlined
                              dense
                              v-model="header.documenttype"
                              :items="documentOptions"
                              :items-value="value"
                              :items-text="text"
                              attach
                              label="Tipo de documento"
                              :disabled="header.purchaseordertype == 'FNS'"
                              required
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              outlined
                              dense
                              v-model="header.purchaselegalinvoicenumber"
                              :label="
                                header.documenttype == '2'
                                  ? 'Código de generación'
                                  : 'Correlativo factura'
                              "
                              v-if="header.purchaseordertype != 'FNS'"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-autocomplete
                              outlined
                              dense
                              v-model="header.paymentMethod"
                              :items="paymentTypes"
                              item-text="Valores"
                              attach
                              label="Método de Pago"
                              required
                            ></v-autocomplete>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-autocomplete
                              outlined
                              dense
                              v-model="header.invoiceStatus"
                              :items="invoiceStatus"
                              label="Estado de la Factura"
                              attach
                              required
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            md="6"
                            v-if="header.invoiceStatus == 'Crédito'"
                          >
                            <v-autocomplete
                              outlined
                              dense
                              v-model="header.days"
                              :items="days"
                              attach
                              label="Plazo"
                            >
                            </v-autocomplete>
                          </v-col>

                          <v-col
                            cols="12"
                            md="6"
                            v-if="
                              header.paymentMethod != 'Billetes y monedas' &&
                              header.paymentMethod != undefined
                            "
                          >
                            <v-text-field
                              outlined
                              dense
                              v-model="header.nreference"
                              label="N. Referencia"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-col class="text-right">
                      <v-btn
                        @click="showInfoPurchase = !showInfoPurchase"
                        class="btndisable"
                      >
                        Continuar
                      </v-btn>
                    </v-col>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-10">
        <v-dialog v-model="showProducts" max-width="1000">
          <v-card>
            <v-card-title class="text-h5">Productos y servicios disponibles</v-card-title>
            <v-card-text>
              <v-row class="mt-2">
                <div style="display: flex; margin-left: 5px">
                  <input
                    type="radio"
                    id="InvoiceProductBtn"
                    value="products"
                    v-model="displayInvoice"
                  />
                  <h5 style="margin: 5px" for="InvoiceProductBtn">Productos</h5>
                  <input
                    style="margin-top: 1px; margin-left: 10px"
                    type="radio"
                    id="InvoiceServiceBtn"
                    value="services"
                    v-model="displayInvoice"
                  />
                  <h5 style="margin: 5px" for="InvoiceServiceBtn">Servicios</h5>
                </div>
              </v-row>

              <v-row>
                <template v-if="displayInvoice == 'products'">
                  <v-col cols="12">
                    <GeneralFilter
                      entity="lista"
                      :filterEndpoint="
                        $API.products.getProductsToBillForPurchaseOrdersFiltered
                      "
                      :search="search"
                      @emptyFilter="getProducts"
                      @filtered="filterHandlerProducts"
                      :isAutoScrolled="true"
                    >
                      <DxDataGrid
                        :data-source="productsToBill"
                        :allow-column-resizing="true"
                        :column-auto-width="true"
                        key-expr="ID_Item"
                        :paging="{ enabled: false }"
                        style="max-height: 250px;"
                      >
                        <DxColumn
                          :width="50"
                          cell-template="show-template"
                          caption=""
                        ></DxColumn>
                        <!--DxPaging :page-size="5" /-->
                        <DxColumn data-field="ID_Item" caption="ID" width="125px">
                        </DxColumn>
                        <DxColumn data-field="BarCode" caption="Código Alterno" width="125px">
                        </DxColumn>
                        <DxColumn
                          data-field="ItemName"
                          caption="Nombre de Producto"
                        ></DxColumn>
                        <DxColumn data-field="Warehouse" caption="Almacén" ></DxColumn>
                        <!--DxColumn data-field="ItemLote" caption="Lote"></DxColumn-->
                        <DxColumn
                          :width="225"
                          data-field="ItemPrice"
                          :calculate-display-value="(rowData => rowData.ItemPrice.toFixed(2))"
                          caption="Precio de Venta"
                        ></DxColumn>
                        

                        <template #show-template="{ data }">
                          <a
                            href="#"
                            class="mx-1"
                            @click.prevent.stop="addProduct(data.data)"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </a>
                        </template>
                      </DxDataGrid>
                    </GeneralFilter>
                  </v-col>
                </template>  

                <template v-if="displayInvoice == 'services'">
                  <v-col cols="12">
                    <GeneralFilter
                      :filterEndpoint="$API.services.getAllServicesFiltered"
                      :search="search"
                      @emptyFilter="getAllServices"
                      @filtered="filterHandlerServices"
                    >
                      <DxDataGrid
                        class="isScrolledByGeneralFilter"
                        :data-source="servicesToBill"
                        key-expr="CodeService"
                        :paging="{ enabled: false }"
                        style="max-height: 250px;"
                      >
                        <DxScrolling row-rendering-mode="virtual" />
                        <!--DxPaging :page-size="5" /-->
                        <DxColumn
                          :width="45"
                          cell-template="show-template"
                          caption=""
                        ></DxColumn>
                        <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                            <v-icon>mdi-plus</v-icon>
                          </a>
                        </template>
                        <DxColumn
                          :width="150"
                          data-field="CodeService"
                          caption="ID"
                        ></DxColumn>
                        <DxColumn
                          data-field="NameService"
                          header-cell-template="headerNameService"
                        ></DxColumn>
                        <template #headerNameService="{}">
                          <div>Nombre de<br />servicio</div>
                        </template>
                        <DxColumn
                          data-field="Name"
                          header-cell-template="headerNameLab"
                        ></DxColumn>
                        <template #headerNameLab="{}">
                          <div>Nombre a<br />facturar</div>
                        </template>
                        <DxColumn
                          :width="130"
                          data-field="TypeService"
                          header-cell-template="headerTypeservice"
                        >
                          <DxLookup
                            :data-source="typeService"
                            display-expr="Name"
                            value-expr="ID"
                          />
                        </DxColumn>
                        <template #headerTypeservice="{}">
                          <div>Tipo de<br />servicio</div>
                        </template>
                        <DxColumn
                          :width="120"
                          cell-template="itemPriceFormatted"
                          caption="Precio"
                        ></DxColumn>
                        <template #itemPriceFormatted="{ data }">
                          {{ this.header.itemcurrency
                          }}{{ numberWithCommas(data.data.Price) }}
                        </template>
                      </DxDataGrid>
                    </GeneralFilter>
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col class="text-right">
                <v-btn @click="showProducts = !showProducts" class="btndisable">
                  Continuar
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="showJsonImport" max-width="500">
          <v-card class="py-5">
            <v-card-title class="mb-3 font-weight-bold text-nowrap"
              >Importar archivo tipo JSON</v-card-title
            >
            <v-card-actions>
              <v-row>
                <v-col cols="12" class="d-flex flex-row flex-wrap">
                  <v-file-input
                    style="min-width: 200px"
                    outlined
                    dense
                    @change="handleFileUpload"
                    accept="application/json"
                    label="Seleccione un JSON"
                    class="mr-3"
                    :rules="[maxFileSize]"
                  >
                  </v-file-input>
                  <v-btn @click="importJsonData" class="btn-add">
                    <v-icon class="mr-2">mdi-import</v-icon> Importar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-col cols="12">
          <div class="box shadow pa-5 border-5 ma-0 mt-2">
            <v-row>
              <v-col>
                <p><b>Elementos de la orden de compra actual</b></p>
              </v-col>
            </v-row>

            <v-row class="mt-6">
              <v-col
                cols="12"
                md="2"
                v-if="$store.getters['rolesUser/getCustomRole'](100)"
              >
                <v-btn class="btn-json mb-3" @click="showJsonImport = true">
                  <v-icon class="mr-2">mdi-import</v-icon>Importar JSON
                </v-btn>
              </v-col>
              <v-col cols="12" md="5" class="d-flex flex-row">
                <v-btn
                  class="btn-add mr-10"
                  @click="showProducts = !showProducts"
                >
                  <v-icon>mdi-magnify</v-icon></v-btn
                >
                <v-text-field
                  outlined
                  dense
                  v-model="selectedBarCode"
                  :items="barCodes"
                  item-text="BarCode"
                  @keyup.enter="handleBarCodEnterKey()"
                  item-value="ID_Item"
                  label="Ingrese código de barras"
                  attach
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="5">
                <v-text-field
                  outlined
                  dense
                  id="input_productcode"
                  label="Ingrese código de producto"
                  v-model="itemcode"
                  @keyup.enter="handleItemCodeEnterKey(itemcode)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-autocomplete
                  outlined
                  dense
                  :items="ItemsByName"
                  v-model="currentIembyname"
                  @change="addproductsby()"
                  item-text="value"
                  item-value="data"
                  label="Agregar por nombre de producto"
                  attach
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  outlined
                  dense
                  :items="ItemsByID"
                  v-model="currentIembyId"
                  @change="addproductsby()"
                  item-text="value"
                  item-value="data"
                  label="Agregar por ID de producto"
                  attach
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-autocomplete
                  outlined
                  dense
                  :items="ItemsServiceByName"
                  v-model="currentItemServiceByName"
                  @change="addservicesby()"
                  item-text="value"
                  item-value="data"
                  label="Agregar por nombre de servicio"
                  attach
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  outlined
                  dense
                  :items="ItemsServiceByID"
                  v-model="currentItemServiceById"
                  @change="addservicesby()"
                  item-text="value"
                  item-value="data"
                  label="Agregar por ID de servicio"
                  attach
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-divider class="my-2"></v-divider>
            </v-row>
            <v-row class="mt-8">
              <v-col cols="12" md="6">
                <v-autocomplete
                  outlined
                  dense
                  :items="warehouses"
                  v-model="globalWarehouse"
                  item-text="Warehouse"
                  item-value="ID_Warehouse"
                  label="Seleccione un almacén para asignar a todos los productos"
                  attach
                ></v-autocomplete>
              </v-col>
              <v-col class="d-flex bg-surface-variant" cols="12" md="6">
                <v-btn class="btn-add" @click="assingWarehouseGlobally()">
                  Asignar a Todos
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex align-end justify-end">
                <v-checkbox
                  label="Factura es crédito fiscal"
                  :value="checkboxIva"
                  @change="handleCheckboxIva"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row style="overflow-x: scroll">
              <DxDataGrid
                class="isScrolledByGeneralFilter mt-4"
                :data-source="
                  productsFiltered === false
                    ? productsToShow
                    : productsToShowFiltered
                "
                key-expr="ID_Item"
              >
                <DxColumn
                  data-field="ID_Item"
                  caption="ID"
                  width="120px"
                ></DxColumn>
                <DxColumn
                  data-field="BarCode"
                  caption="Código alterno"
                ></DxColumn>
                <DxColumn data-field="ItemName" caption="Producto"></DxColumn>
                <DxColumn
                  width="100px"
                  cell-template="product-batch"
                  caption="Lote"
                ></DxColumn>
                <DxColumn
                  cell-template="product-expirationdate"
                  caption="Expiración"
                  width="100px"
                ></DxColumn>
                <DxColumn
                  cell-template="warehouse-selection"
                  caption="Almacen"
                  width="150px"
                ></DxColumn>
                <DxColumn
                  cell-template="product-quantity"
                  caption="Cantidad"
                  width="90px"
                ></DxColumn>
                <DxColumn
                  width="90px"
                  cell-template="product-unitcost"
                  caption="Cost./U"
                ></DxColumn>
                <!--DxColumn cell-template="Isiva" caption="Iva" width="90px">
                </DxColumn-->
                <DxColumn cell-template="IsExento" caption="Exento" width="90px"></DxColumn>
                <template #IsExento="{ data }">
                  <input type="checkbox" @click="data.data.IsNoSujeto = false;" v-model="data.data.IsExento" />
                </template>
                <DxColumn cell-template="IsNoSujeto" caption="No Sujeto" width="90px"></DxColumn>
                <template #IsNoSujeto="{ data }">
                  <input type="checkbox" @click="data.data.IsExento = false;" v-model="data.data.IsNoSujeto" />
                </template>
                
                <DxColumn
                  width="90px"
                  cell-template="total-quantity"
                  caption="Total"
                ></DxColumn>
                <DxColumn
                  :width="32"
                  cell-template="show-template"
                  caption=""
                ></DxColumn>

                <template #warehouse-selection="{ data }">
                  <span class="scroll-fixing-purchase">
                    <select
                      class="form-control custom-select"
                      v-model="data.data.Warehouse"
                      @change="updateWarehouse(data.data, $event.target.value)"
                    >
                      <option
                        v-for="warehouse in warehouses"
                        :value="warehouse.Warehouse"
                        :key="warehouse.ID_Warehouse"
                      >
                        {{ warehouse.Warehouse }}
                      </option>
                    </select>
                  </span>
                </template>

                <template #show-template="{ data }">
                  <a
                    href="#"
                    class="mx-1"
                    @click.prevent.stop="
                      showCustomer = data;
                      removeRow(data);
                    "
                  >
                    <v-icon color="error">mdi-delete</v-icon>
                  </a>
                </template>

                <template #product-batch="{ data }">
                  <input v-if="data.data.ItemType != 'service'" type="text" v-model="data.data.ID_ItemBatch" />
                </template>
                <template #product-expirationdate="{ data }">
                  <input v-if="data.data.ItemType != 'service'" type="date" v-model="data.data.ExpirationDate" />
                </template>
                <template #product-quantity="{ data }">
                  <input type="number" v-model="data.data.qty" />
                </template>
                <template #product-unitcost="{ data }">
                  <input
                    type="number"
                    v-model="data.data.ItemNewPrice"
                    value="0"
                  />
                </template>
                <template #Isiva="{ data }">
                  <input type="checkbox" @click="data.data.IsExento = false; data.data.IsNoSujeto = false;" v-model="data.data.iva" />
                </template>
                <template #total-quantity="{ data }">
                  $ {{ data.data.ItemPriceQty }}
                </template>
              </DxDataGrid>
              <!-- <GeneralFilter style="width: 100%;" :search="{}" :filterEndpoint="filterPromise" @emptyFilter="clearFilter" @filtered="filterHandler" > -->
              <!-- <DxDataGrid class="isScrolledByGeneralFilter" :data-source="producstFiltered ? productsToShow : productsToShowFiltered" key-expr="ID_Item" class="mt-4" style="min-width: 1000px"> -->
              <!-- </GeneralFilter> -->
            </v-row>
            <v-col class="mt-5 box invoiceResponsiveSummary">
              <v-row cols="12" class="mt-3 mb-1">
                <v-col cols="6" md="3" class="responsiveSmallCol mb-5">
                  <v-row class="d-flex justify-center">
                    <span id="subtotal">Subtotal</span>
                  </v-row>
                  <v-row class="d-flex justify-center mb-1">
                    <input
                      type="text"
                      class="form-control"
                      :value="sumObject.subTotal"
                      disabled
                      aria-describedby="subtotal"
                    />
                  </v-row>
                </v-col>
                <v-col cols="6" md="2" class="responsiveSmallCol mb-5" v-if="header.purchaseordertype == 'CCF'">
                  <v-row class="d-flex justify-center">
                    <span id="IVA">IVA 13%</span>
                  </v-row>
                  <v-row class="d-flex justify-center mb-1">
                    <input
                      type="text"
                      class="form-control"
                      :value="sumObject.iva"
                      disabled
                      aria-describedby="IVA"
                    />
                  </v-row>
                </v-col>
                <v-col cols="6" md="3" class="responsiveSmallCol mb-5">
                  <v-row class="d-flex justify-center">
                    <span id="NON-TAXABLE">Exento</span>
                  </v-row>
                  <v-row class="d-flex justify-center mb-1">
                    <input
                      type="text"
                      class="form-control"
                      :value="sumObject.exento"
                      disabled
                      aria-describedby="NON-TAXABLE"
                    />
                  </v-row>
                </v-col>
                <v-col cols="6" md="3" class="responsiveSmallCol mb-5">
                  <v-row class="d-flex justify-center">
                    <span id="NON-TAXABLE">No Sujeto</span>
                  </v-row>
                  <v-row class="d-flex justify-center mb-1">
                    <input
                      type="text"
                      class="form-control"
                      :value="sumObject.nosujeto"
                      disabled
                      aria-describedby="NON-TAXABLE"
                    />
                  </v-row>
                </v-col>
                <v-col cols="6" md="3" class="responsiveSmallCol mb-5">
                  <v-row class="d-flex justify-center">
                    <span id="NON-TAXABLE">{{
                      header.purchaseordertype == "FNS"
                        ? "Rete Renta"
                        : "IVA Retenido (US$)"
                    }}</span>
                  </v-row>
                  <v-row class="d-flex justify-center mb-1">
                    <input
                      type="text"
                      class="form-control"
                      :value="sumObject.ivaret"
                      disabled
                      aria-describedby="NON-TAXABLE"
                    />
                  </v-row>
                </v-col>
                <v-col cols="6" md="3" class="responsiveSmallCol mb-5" v-if="header.purchaseordertype == 'CCF'">
                  <v-row class="d-flex justify-center">
                    <span id="NON-TAXABLE">IVA Percibido (US$)</span>
                  </v-row>
                  <v-row class="d-flex justify-center mb-1">
                    <input
                      type="text"
                      class="form-control"
                      :value="sumObject.ivaperc"
                      disabled
                      aria-describedby="NON-TAXABLE"
                    />
                  </v-row>
                </v-col>
                <v-col cols="6" md="4" class="responsiveSmallCol mb-5">
                  <v-row class="d-flex justify-center">
                    <span id="VENTAS-TOTAL">Total (US$)</span>
                  </v-row>
                  <v-row class="d-flex justify-center mb-1">
                    <input
                      type="text"
                      class="totalDesignGlobal form-control"
                      :value="sumObject.total"
                      disabled
                      aria-describedby="VENTAS-TOTAL"
                    />
                  </v-row>
                </v-col>
              </v-row>
              <v-row cols="12" class="mt-5 mb-1 d-flex justify-center">
                <!-- <v-col cols="3">
                  </v-col> -->
                <v-col cols="6" md="2"  class="d-flex justify-center  mb-5">
                  <v-btn
                    class="btnclean"
                    style="height: 45px"
                    @click.prevent.stop="resetData()"
                  >
                    <v-icon>mdi-broom</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="6" md="2" class="mb-5 d-flex justify-content-center " >
                  <v-btn
                    v-if="$store.getters['rolesUser/getCustomRole'](99)"
                    style="height: 45px"
                    class="btn-add mx-auto"
                    @click="
                      print = {
                        show: true,
                        data: {
                          header: header,
                          extraHeader: {
                            ID_Provider: data.ID_Provider,
                            img: url,
                          },
                          body: productsToShow,
                          totalinvoice: sumObject,
                        },
                      }
                    "
                  >
                    <v-icon>mdi-printer-pos</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="3"   class="d-flex justify-center mb-5">
                  <v-btn
                    style="height: 45px"
                    class="btn-add"
                    @click.prevent.stop="addPurchaseOrder('notprocess')"
                    v-if="$store.getters['rolesUser/getCustomRole'](101)"
                    :disabled="disableButtons"
                  >
                    GUARDAR ORDEN
                    <br />
                    DE COMPRA
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="mb-5 d-flex justify-center "  >
                  <v-btn
                    v-if="
                      header.purchaseordertype != 'FNS' &&
                      $store.getters['rolesUser/getCustomRole'](98)
                    "
                    style="height: 45px"
                    class="btnsave align-self-center"
                    @click.prevent.stop="addPurchaseOrder('process')"
                    :disabled="disableButtons"
                  >
                    PROCESAR ORDEN
                    <br />
                    DE COMPRA
                  </v-btn>

                  <v-btn
                    v-if="
                      header.purchaseordertype == 'FNS' &&
                      $store.getters['rolesUser/getCustomRole'](98)
                    "
                    style="height: 45px"
                    class="btnsave"
                    @click.prevent.stop="addFnsPurchaseOrder()"
                    :disabled="disableButtons"
                  >
                    PROCESAR ORDEN
                    <br />
                    DE COMPRA
                  </v-btn>
                </v-col>
                <!-- <v-col cols="1">
                  </v-col> -->
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    >
    </alerts>
    <print-purchase-order :data="print"></print-purchase-order>

    <footer
      class="box navbar toHide"
      v-if="$store.getters.getDrawerMini == false"
      style="
        align-self: flex-end;
        display: flex;
        width: calc(100% - 300px);
        background: #41464c;
        color: white;
        bottom: 0;
        left: 240px;
        align-self: flex-start;
        z-index: 99999999;
        justify-content: space-between;
        position: fixed;
        flex-wrap: wrap
        
      "
    >
      <div class="input-group mb-3 ml-2">
        <div class="input-group-prepend">
          <span class="input-group-text" id="subtotal">Sub Total</span>
        </div>
        <input
          type="text"
          class="form-control"
          :value="sumObject.subTotal"
          disabled
          aria-describedby="subtotal"
        />
      </div>
      <div class="input-group mb-3" v-if="header.purchaseordertype == 'CCF'">
        <div class="input-group-prepend">
          <span class="input-group-text" id="IVA">IVA 13%</span>
        </div>
        <input
          type="text"
          class="form-control"
          :value="sumObject.iva"
          disabled
          aria-describedby="IVA"
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="IVA">Exento</span>
        </div>
        <input
          type="text"
          class="form-control"
          :value="sumObject.exento"
          disabled
          aria-describedby="IVA"
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="IVA">No Sujeto</span>
        </div>
        <input
          type="text"
          class="form-control"
          :value="sumObject.nosujeto"
          disabled
          aria-describedby="IVA"
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="NON-TAXABLE">{{
            header.purchaseordertype == "FNS"
              ? "Rete Renta"
              : "IVA Retenido"
          }}</span>
        </div>
        <input
          type="text"
          class="form-control"
          :value="sumObject.ivaret"
          disabled
          aria-describedby="NON-TAXABLE"
        />
      </div>
      <div class="input-group mb-3" v-if="header.purchaseordertype == 'CCF'">
        <div class="input-group-prepend">
          <span class="input-group-text" id="NON-TAXABLE">IVA Percibido</span>
        </div>
        <input
          type="text"
          class="form-control"
          :value="sumObject.ivaperc"
          disabled
          aria-describedby="NON-TAXABLE"
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend d-flex justify-center">
          <span class="input-group-text" id="VENTAS-TOTAL">TOTAL (US$)</span>
        </div>
        <input
          type="text"
          class="form-control totalDesignGlobal"
          :value="sumObject.total"
          disabled
          aria-describedby="VENTAS-TOTAL"
        />
      </div>
      <div style="text-align: center" class="mb-2">
        <v-btn
          style="margin-top: 5px; height: 45px"
          class="btnclean"
          @click.prevent.stop="resetData()"
        >
          <v-icon>mdi-broom</v-icon>
        </v-btn>
      </div>
      <div style="text-align: center" class="mb-2">
        <v-btn
          style="margin-top: 5px; height: 45px"
          class="btn-add"
          @click="
            print = {
              show: true,
              data: {
                header: header,
                extraHeader: { ID_Provider: data.ID_Provider, img: url },
                body: productsToShow,
                totalinvoice: sumObject,
              },
            }
          "
        >
          <v-icon>mdi-printer-pos</v-icon>
        </v-btn>
      </div>

      <div style="text-align: center" class="mb-2">
        <v-btn
          style="margin-top: 5px; height: 45px"
          class="btn-add"
          @click.prevent.stop="addPurchaseOrder('notprocess')"
          v-if="$store.getters['rolesUser/getCustomRole'](101)"
          :disabled="disableButtons"
        >
          GUARDAR ORDEN
          <br />
          DE COMPRA
        </v-btn>
      </div>

      <div style="text-align: center" class="mb-2">
        <v-btn
          v-if="
            header.purchaseordertype != 'FNS' &&
            $store.getters['rolesUser/getCustomRole'](98)
          "
          style="margin-top: 5px; height: 45px"
          class="btnsave"
          @click.prevent.stop="addPurchaseOrder('process')"
          :disabled="disableButtons"
        >
          PROCESAR ORDEN
          <br />
          DE COMPRA
        </v-btn>

        <v-btn
          v-if="
            header.purchaseordertype == 'FNS' &&
            $store.getters['rolesUser/getCustomRole'](98)
          "
          style="margin-top: 5px; height: 45px"
          class="btnsave"
          @click.prevent.stop="addFnsPurchaseOrder()"
          :disabled="disableButtons"
        >
          PROCESAR ORDEN
          <br />
          DE COMPRA
        </v-btn>
      </div>
    </footer>

    <footer
      class="box navbar toHide"
      v-if="$store.getters.getDrawerMini == true"
      style="
        align-self: flex-end;
        display: flex;
        min-width: calc(100% - 90px);
        background: #41464c;
        color: white;
        bottom: 0;
        left: 40px;
        align-self: flex-start;
        z-index: 99999999;
        justify-content: space-between;
        position: fixed;
      "
    >
      <div class="input-group mb-3 ml-2">
        <div class="input-group-prepend">
          <span class="input-group-text" id="subtotal">Sub Total</span>
        </div>
        <input
          type="text"
          class="form-control"
          :value="sumObject.subTotal"
          disabled
          aria-describedby="subtotal"
        />
      </div>
      <div class="input-group mb-3" v-if="header.purchaseordertype == 'CCF'">
        <div class="input-group-prepend">
          <span class="input-group-text" id="IVA">IVA 13%</span>
        </div>
        <input
          type="text"
          class="form-control"
          :value="sumObject.iva"
          disabled
          aria-describedby="IVA"
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="IVA">Exento</span>
        </div>
        <input
          type="text"
          class="form-control"
          :value="sumObject.exento"
          disabled
          aria-describedby="IVA"
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="IVA">No Sujeto</span>
        </div>
        <input
          type="text"
          class="form-control"
          :value="sumObject.nosujeto"
          disabled
          aria-describedby="IVA"
        />
      </div>

      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="NON-TAXABLE">{{
            header.purchaseordertype == "FNS"
              ? "Rete Renta"
              : "IVA Retenido (US$)"
          }}</span>
        </div>
        <input
          type="text"
          class="form-control"
          :value="sumObject.ivaret"
          disabled
          aria-describedby="NON-TAXABLE"
        />
      </div>

      <div class="input-group mb-3" v-if="header.purchaseordertype == 'CCF'">
        <div class="input-group-prepend">
          <span class="input-group-text" id="NON-TAXABLE">IVA Percibido (US$)</span>
        </div>
        <input
          type="text"
          class="form-control"
          :value="sumObject.ivaperc"
          disabled
          aria-describedby="NON-TAXABLE"
        />
      </div>

      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="VENTAS-TOTAL">TOTAL (US$)</span>
        </div>
        <input
          type="text"
          class="form-control"
          :value="sumObject.total"
          disabled
          aria-describedby="VENTAS-TOTAL"
        />
      </div>

      <div style="text-align: center" class="mb-2">
        <v-btn
          style="margin-top: 10px"
          class="btnclean"
          @click.prevent.stop="resetData()"
        >
          <v-icon>mdi-broom</v-icon>
        </v-btn>
      </div>

      <div style="text-align: center" class="mb-2">
        <v-btn
          style="margin-top: 10px"
          class="btn-add"
          @click="
            print = {
              show: true,
              data: {
                header: header,
                extraHeader: { ID_Provider: data.ID_Provider, img: url },
                body: productsToShow,
                totalinvoice: sumObject,
              },
            }
          "
        >
          <v-icon>mdi-printer-pos</v-icon>
        </v-btn>
      </div>

      <div style="text-align: center" class="mb-2">
        <v-btn
          v-if="$store.getters['rolesUser/getCustomRole'](101)"
          style="margin-top: 10px"
          class="btn-add"
          @click.prevent.stop="addPurchaseOrder('notprocess')"
          :disabled="disableButtons"
        >
          GUARDAR ORDEN
          <br />
          DE COMPRA
        </v-btn>
      </div>

      <div style="text-align: center" class="mb-2">
        <v-btn
          v-if="
            header.purchaseordertype != 'FNS' &&
            $store.getters['rolesUser/getCustomRole'](98)
          "
          style="margin-top: 10px"
          class="btnsave"
          @click.prevent.stop="addPurchaseOrder('process')"
          :disabled="disableButtons"
        >
          PROCESAR ORDEN
          <br />
          DE COMPRA
        </v-btn>

        <v-btn
          v-if="
            header.purchaseordertype == 'FNS' &&
            $store.getters['rolesUser/getCustomRole'](98)
          "
          style="margin-top: 10px"
          class="btnsave"
          @click.prevent.stop="addFnsPurchaseOrder()"
        >
          PROCESAR ORDEN
          <br />
          DE COMPRA
        </v-btn>
      </div>
    </footer>

    <print-pos-fa
      :dataProps="print2"
      @mailSent="
        () => {
          showAlert(
            'success',
            'Correo enviado',
            'Se ha enviado el detalle de la factura correctamente'
          );
        }
      "
    ></print-pos-fa>
  </div>
</template>

<script>
import DatePicker from "../../../components/DatePicker";
import Block from "@/components/Block";
import Vue from "vue";
import { DxDataGrid, DxColumn } from "devextreme-vue/data-grid";
import Alerts from "@/components/Alerts";
import PrintPurchaseOrder from "../../../components/ReportsAndPrintings/PrintPurchaseOrder";
import { mapState } from "vuex";
import GeneralFilter from "@/components/GeneralFilter";
import { multipleCriteriaFilter } from "@/helpers/object";
import { formatDate } from "@/helpers/formatDate";
import { mapGetters } from "vuex";
import PrintPosFa from "@/components/ReportsAndPrintings/PrintPosFa";
import { numberToText } from "../../../helpers/conveterNumberText";

const printPosFaConstructor = Vue.extend(PrintPosFa);

export default {
  name: "AddPurchase",
  props: ["data", "win"],
  components: {
    DatePicker,
    DxDataGrid,
    DxColumn,
    GeneralFilter,
    Block,
    Alerts,
    PrintPurchaseOrder,
    PrintPosFa,
  },
  data() {
    return {
      showComment: false,
      showBgcolorNotes: "tabs",
      showInfoPurchase: false,
      showProducts: false,
      invoiceStatus: ["Pendiente de pago", "Cancelada", "Crédito"],
      paymentMethod: [
        "Efectivo",
        "Cheque",
        "Transferencia",
        "Tarjeta de Crédito",
        "Otro",
      ],
      days: ["8 Dias", "15 Dias", "30 Dias", "90 Dias", "120 Dias", "180 Dias"],
      print: {
        show: false,
        data: [],
      },
      print2: {
        show: false,
        data: [],
      },
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      form: {},
      requiredRule: [(value) => !!value || "Required."],
      valid: false,
      url: null,
      image: null,
      warehouses: [],
      currentIembyId: null,
      currentIembyname: null,
      currentItemServiceById: null,
      currentItemServiceByName: null,
      loading: false,
      value: null,
      readonly: true,
      orderid: null,
      purchaseorderdate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      purchaseinvoicedate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      //This object use for loading de elements for select box "Invoice Type"
      invoiceTypes: [],
      //items:[],
      //Object that contains the info of purchase order header
      header: {},
      //Objects for the section "Filters"
      showFilter: false,
      showJsonImport: false,
      filterProducts: {},
      detailsToshow: false,
      priceList: [
        {
          PriceListName: "TODOS",
        },
      ],
      types: ["TODOS", "PRODUCTO"],
      //Objects for the tables of products
      productsToBill: [],
      servicesToBill: [],
      productsToShow: [],
      barcode: null,
      itemcode: null,
      taxPayerSize: null,
      documentOptions: [
        { value: "1", text: "Físico" },
        { value: "2", text: "Electrónico" },
      ],
      productsToShowFiltered: [],
      productsFiltered: false,
      search: {
        filter: null,
      },
      loadingJson: false,
      catalogTypedocument: [],
      selectedFile: null,
      jsonData: null,
      barCodes: [],
      selectedBarCode: null,
      globalWarehouse: null,
      barCodeToFind: null,
      datatoprint: {
        branch: JSON.parse(localStorage.getItem("branch")),
        user: JSON.parse(localStorage.getItem("user")),
        header: null,
        body: null,
        dteJson: null,
      },
      displayInvoice: "products",
      checkboxIva: false,
    };
  },
  created() {
    this.$store.dispatch("getParam", { type: "Invoice" }).then((data) => {

      this.invoiceTypes = data.map((param) => {
        return { text: param.ParameterName, value: param.ParameterValue };
      });
    });
  },
  watch: {
    filterProducts: {
      deep: true,
      handler: "getProducts",
    },
    productsToShow: {
      deep: true,
      handler(newValue) {
        newValue.forEach((data) => {
          if (data.iva) {
            data.ItemPriceQty = (data.ItemNewPrice * data.qty * 1.13).toFixed(
              4
            );
          } else if (!data.iva) {
            if (data.IsExento || data.IsNoSujeto) {
              data.ItemPriceQty = ((data.ItemNewPrice * data.qty) / 1.13).toFixed(4);
            }
            else {
              data.ItemPriceQty = (data.ItemNewPrice * data.qty).toFixed(4);
            }
            
          }
        });
      },
    },
    purchaseorderdate: {
      handler: function () {
        this.header.purchaseorderdate = this.purchaseorderdate;
      },
      immediate: true,
    },
    purchaseinvoicedate: {
      handler: function () {
        this.header.purchaseinvoicedate = this.purchaseinvoicedate;
      },
      inmmediate: true,
    },
    "header.purchaseordertype"() {
      this.sumObject()
    }
  },
  computed: {
    disableButtons(){
      return this.loading || this.alert.show;
    },
    ...mapGetters(['getCatalogMH']),
    paymentTypes() {
      return this.getCatalogMH("FormaPago");
    },
    sumObject() {
      let sum = this.productsToShow.reduce(
        (prev_value, next_value) => {
          let price = parseFloat(next_value.ItemPriceQty);
          return {
            subTotal:
              (next_value.IsExento == false && next_value.IsNoSujeto == false) ?
                (
                  (this.header.purchaseordertype == 'CCF' )
                  ? prev_value.subTotal + price / 1.13 : prev_value.subTotal + price
                ) : 0,
            iva:
              (next_value.IsExento == false && next_value.IsNoSujeto == false) ?
                (
                  (this.header.purchaseordertype == 'CCF' )
                  ? prev_value.iva + (price - price / 1.13) : prev_value.iva + 0
                ) : prev_value.iva + 0,
            ivaret: 0,
            ivaperc: 0,
            nosujeto: next_value.IsNoSujeto ? prev_value.nosujeto + price : prev_value.nosujeto + 0,
            exento: next_value.IsExento ? prev_value.exento + price : prev_value.exento + 0,
          };
        },
        { subTotal: 0, iva: 0, ivaret: 0, ivaperc: 0, nosujeto: 0, exento: 0 }
      );
      let reterenta = 0;
      let sumtmp = parseFloat(sum.subTotal) + parseFloat(sum.iva)
      if (this.header.purchaseordertype == "FNS") {
        for(let i = 0; i < this.productsToShow.length; i++) {
          if(this.productsToShow[i].ItemType == "service") {
            reterenta += parseFloat(parseFloat(this.productsToShow[i].ItemNewPrice) * 0.1)
          }
        }
        sum.ivaret = reterenta
        sum.ivaperc = 0
      }
      else if (this.header.purchaseordertype == "FCF") {
        if (
          (this.data.ProviderLegalTaxPayerSize == "PEQUEÑO" &&
            (this.taxPayerSize == "GRANDE" || this.taxPayerSize == "MEDIANO")) ||
          (this.data.ProviderLegalTaxPayerSize == "MEDIANO" &&
            this.taxPayerSize == "GRANDE")
        ) {
          if (sumtmp >= 113) {
            sum.ivaret = parseFloat(parseFloat(sum.subTotal) * 0.01)
          } 
          else {
            sum.ivaret = 0;
          }
        }
        else {
          sum.ivaret = 0;
          sum.ivaperc = 0;
        }
      }
      else if (this.header.purchaseordertype == "CCF") {
        if (
          this.data.ProviderLegalTaxPayerSize == "GRANDE" &&
          (this.taxPayerSize == "PEQUEÑO" || this.taxPayerSize == "MEDIANO")
        ) {
          if (sumtmp >= 113) {
            sum.ivaperc = parseFloat(parseFloat(sum.subTotal) * 0.01)
          }
          else {
            sum.ivaperc = 0;
          }

          sum.ivaret = 0;
        }
        else {
          sum.ivaperc = 0;
        }

        if (this.taxPayerSize == "GRANDE" && 
          (this.data.ProviderLegalTaxPayerSize == "PEQUEÑO" || 
            this.data.ProviderLegalTaxPayerSize == "MEDIANO"
          ) 
        ){
          if (sumtmp >= 113) {
            sum.ivaret = parseFloat(parseFloat(sum.subTotal) * 0.01)
          }
          else {
            sum.ivaret = 0;
          }

          sum.ivaperc = 0;
        }
        else {
          sum.ivaret = 0;
        }
      }
      else {
        sum.ivaret = 0;
        sum.ivaperc = 0;
      }



      sum.subTotal = parseFloat(sum.subTotal).toFixed(4);
      sum.iva = parseFloat(sum.iva).toFixed(4);
      sum.ivaret = parseFloat(sum.ivaret).toFixed(4);
      sum.ivaperc = parseFloat(sum.ivaperc).toFixed(4);
      sum.exento = parseFloat(sum.exento).toFixed(4);
      sum.nosujeto = parseFloat(sum.nosujeto).toFixed(4);

      sum.total = parseFloat(
        parseFloat(sum.subTotal) + parseFloat(sum.iva) - parseFloat(sum.ivaret) + parseFloat(sum.ivaperc)
        + parseFloat(sum.exento) + parseFloat(sum.nosujeto)
      ).toFixed(2);

      return sum;
    },
    ...mapState("providersData", {
      orderDetails: (state) => state.orderDetails,
      order: (state) => state.order,
    }),

    ItemsByName() {
      if (!this.productsToBill) return [];
      return this.productsToBill.map((item) => {
        return { data: item, value: item.ItemName };
      });
    },
    ItemsByID() {
      if (!this.productsToBill) return [];
      return this.productsToBill.map((item) => {
        return { data: item, value: item.ID_Item };
      });
    },
    ItemsServiceByName() {
      if (!this.servicesToBill) return [];
      return this.servicesToBill.map((item) => {
        return { data: item, value: item.Name || item.NameService}
      })
    },
    ItemsServiceByID() {
      if (!this.servicesToBill) return [];
      return this.servicesToBill.map((item) => {
        return { data: item, value: item.CodeService };
      });
    }
  },
  async mounted() {
    try {
      this.$store.dispatch("rolesUser/getAllRoles");

      let idbusiness =
        JSON.parse(localStorage.getItem("user")).businessid || "";
      let idbranch = JSON.parse(localStorage.getItem("user")).branch || "";
      let user = JSON.parse(localStorage.getItem("user")).id || "";

      this.form.ID_Business = idbusiness;
      this.form.ID_Branch = idbranch || "";
      this.form.ID_User_Insert = user;

      this.filterProducts.business = idbusiness;
      this.filterProducts.branch = idbranch;

      this.filterProducts.provider = this.data.ID_Provider;

      this.getAllWarehouses();

      this.getCatalogTypedocument();

      await this.$API.products.priceList().then((response) => {
        response.map((item, index) => {
          Vue.set(this.priceList, index + 1, item);
        });
      });

      await this.getProducts();
      await this.getAllServices();
      
      this.header = {
        purchaseordername: this.data.ProviderName,
        purchaseorderemail: this.data.ProviderEmail,
        purchaseordermobile: this.data.ProviderMobileNumber,
        purchaseorderphone: this.data.ProviderPhone,
        purchaseordercountry: this.data.ProviderCountry,
        purchaseordercontactmobile: this.data.ProviderContactMobile,
        purchaseordercontactphone: this.data.ProviderContactPhone,
        purchaseordercontactemail: this.data.ProviderContactEmail,
        purchaseorderstate: this.data.ProviderState,
        purchaseordercity: this.data.ProviderCity,
        purchaseordernotes: this.data.Notes,
        purchaseordercontactname: this.data.ProviderContactName,
        purchaseorderaddress: this.data.ProviderAddress,
        purchaseordernrc: this.data.ProviderLegalNRC,
        purchaseordernit: this.data.ProviderLegalNIT,
        purchaseordersector: this.data.ProviderLegalSector,
        purchaseorderentity: this.data.ProviderLegalEntity,
        purchaseordertaxpayersize: this.data.ProviderLegalTaxPayerSize,
        purchaseorderisnontaxable: this.data.ProviderLegalIsNonTaxable
          ? this.data.ProviderLegalIsNonTaxable
          : "",
        subtotal: this.sumObject.subTotal,
        iva: this.sumObject.iva,
        incometax: this.sumObject.nontaxable,
        ivaret: this.sumObject.ivaret,
        total: this.sumObject.total,
        // purchaseordertotalinletters: this.totalInSpanish(),
        provider: this.data.ID_Provider || "",
        // warehouse: this.state.warehouseEntry,
        itemcurrency: "$",
        idbranch: this.form.ID_Branch,
        idbusiness: this.form.ID_Business,
        purchaseinvoicedate: this.purchaseinvoicedate,
        purchaseorderdate: this.purchaseorderdate,
      };

      if (this.orderDetails != null) {
        this.detailsToshow = true;
        this.getAllWarehouses();
        this.header.days =
          this.order.days != undefined || this.order.days != null
            ? this.order.days
            : "30 Dias";
        this.header.invoiceStatus = this.order.InvoiceState
          ? this.order.InvoiceState
          : null;
        this.header.paymentMethod = this.order.paymenMethod
          ? this.order.paymenMethod
          : null;
      } else {
        await this.getPurchaseId();
        this.header.days = "30 Dias";
      }

      this.getInfoBranch();
    } catch (error) {
      console.log(error);
    }
    // Get barcodes
    this.getAllBarCodes(this.data.ID_Provider);
  },
  methods: {
    printPosFaComponent(options, DOM = document.body) {
      // console.log(options);
      const printPosFaInstance = new printPosFaConstructor({
        propsData: {
          dataProps: options,
        },
      });
      printPosFaInstance.vm = printPosFaInstance.$mount();
      const dom = DOM;
      dom.appendChild(printPosFaInstance.vm.$el);
      return printPosFaInstance.vm;
    },
    addValidToTypeInvoice(calculateIva = false) {
      if (this.header.purchaseordertype == "FNS") {
        this.header.documenttype = this.documentOptions[1].value;
        this.header.purchaselegalinvoicenumber = null;
        this.invoiceStatus = ["Cancelada", "Crédito"];
        this.header.invoiceStatus = this.invoiceStatus[0];
      } else {
        this.invoiceStatus = ["Pendiente de pago", "Cancelada", "Crédito"];
      }

      if (calculateIva) {
        if (this.header.purchaseordertype == 'CCF') {
          this.checkboxIva = true
          this.productsToShow.forEach((item) => {
            Vue.set(item, "iva", true);
          });
        }
        else {
          this.checkboxIva = false
          this.productsToShow.forEach((item) => {
            Vue.set(item, "iva", false);
          });
        }
      }
    },
    assingWarehouseGlobally() {
      if (this.globalWarehouse == null || this.globalWarehouse == "") {
        this.showAlert(
          "warning",
          "Advertencia",
          "Por favor seleccionar un almacén."
        );
      } else {
        let found = this.warehouses.find((wh) => wh.ID_Warehouse == this.globalWarehouse)
        for (const index in this.productsToShow) {
          this.productsToShow[index].Warehouse = found.Warehouse;
          this.productsToShow[index].ID_Warehouse = found.ID_Warehouse
        }
      }
    },
    getBarCode(ID_Provider, barcode) {
      this.loading = true;
      this.$API.inventoryitemsbarcodes
        .getBarCodeProvidersById({ ID_Provider, barcode })
        .then((data) => {
          this.barCodeToFind = data;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
          if (
            this.barCodeToFind &&
            this.barCodeToFind.ID_Item &&
            typeof this.barCodeToFind.ID_Item === "string"
          ) {
            this.addByBarCode();
          } else {
            this.showAlert(
              "danger",
              "Advertencia",
              "Código de barra no válido."
            );
          }

          this.selectedBarCode = ""
        });
    },
    getAllBarCodes(ID_Provider) {
      this.$API.inventoryitemsbarcodes
        .getAllBarCodes(ID_Provider)
        .then((data) => {
          this.barCodes = data;
        })
        .catch((error) => console.log(error));
    },
    clearFilter() {
      this.productsFiltered = false;
    },
    filterHandler(response) {
      this.productsFiltered = true;
      this.productsToShowFiltered = response;
    },
    getCatalogTypedocument() {
      this.$API.catalogtypedocument
        .getCatalogTypeDocumentForProvider()
        .then(({ data }) => {
          this.catalogTypedocument = data;
        });
    },
    handleFileUpload(event) {
      // Get the selected file from the event
      this.selectedFile = event;
    },
    importJsonData() {
      if (this.selectedFile) {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            this.jsonData = JSON.parse(reader.result);
            if (!this.jsonData.identificacion || !this.jsonData.resumen) {
              throw new Error("Formato no válido del JSON.");
            }
          } catch (error) {
            this.showJsonImport = false;
            this.showAlert("warning", "Advertencia", `${error}`);
          } finally {
            this.showJsonImport = false;
            this.showDataImported({ ...this.jsonData });
          }
        };
        reader.readAsText(this.selectedFile);
      }
    },
    showDataImported({ identificacion, resumen }) {
      this.$nextTick(() => {
        this.$refs["inputdatepicker"].dateFormatted = formatDate(
          identificacion.fecEmi
        );
        this.$refs["inputdatepicker"].date = identificacion.fecEmi;
        console.log(this.$refs["inputdatepicker"]);
        console.log(identificacion.fecEmi);
      });
      this.showInfoPurchase = true;
      this.header.purchaseordertype = this.catalogTypedocument.find(
        ({ code }) => code === identificacion.tipoDte
      ).value;
      this.header.documenttype = this.documentOptions[1].value;
      this.header.purchaselegalinvoicenumber = identificacion.codigoGeneracion;
      switch (resumen.condicionOperacion) {
        case 1:
          // cancelada
          this.header.invoiceStatus = this.invoiceStatus[1];
          break;
        case 2:
          // credito
          this.header.invoiceStatus = this.invoiceStatus[3];
          break;
        default:
          // pendiente de pago
          this.header.invoiceStatus = this.invoiceStatus[0];
          break;
      }
      this.purchaseinvoicedate = formatDate(identificacion.fecEmi);
    },
    async getInfoBranch() {
      let idbusiness =
        JSON.parse(localStorage.getItem("user")).businessid || "";
      let idbranch = JSON.parse(localStorage.getItem("user")).branch || "";
      this.$API.branches
        .findSpecificBranch({ branch: idbranch, business: idbusiness })
        .then((res) => {
          const infobusiness = res[0];
          this.datatoprint.branch.AuthFeMode = infobusiness.AuthFeMode;
          this.datatoprint.branch.BusinessBillingNRC =
            infobusiness.BusinessBillingNRC;
          this.datatoprint.branch.BusinessBillingNIT =
            infobusiness.BusinessBillingNIT;
          this.datatoprint.branch.BusinessBillingName =
            infobusiness.BusinessBillingName;
          this.taxPayerSize = infobusiness.BusinessTaxPayerSize;
        });
    },
    filterPromise(filterObj) {
      const filterArray = [
        "ID_Item",
        "ItemName",
        "Warehouse",
        "ID_ItemBatch",
        "iva",
        "ItemNewPrice",
        "ItemPriceQty",
        "qty",
        "ExpirationDate",
      ];

      return new Promise((resolve, reject) => {
        try {
          resolve(
            multipleCriteriaFilter(filterArray, filterObj, this.productsToShow)
          );
        } catch (error) {
          reject(error);
        }
      });
    },
    async getAllWarehouses() {
      let idbusiness =
        JSON.parse(localStorage.getItem("user")).businessid || "";
      let idbranch = JSON.parse(localStorage.getItem("user")).branch || "";
      let filterWarehouses = {};
      filterWarehouses.branch = idbranch;
      filterWarehouses.business = idbusiness;
      this.$API.warehouse.getWarehouses(filterWarehouses).then((response) => {
        this.warehouses = response;

        let found = this.warehouses.find((wh) => wh.Active == true && wh.ID_Branch == idbranch)

        if (found) {
          this.globalWarehouse = found.ID_Warehouse
        }

        if (this.detailsToshow) this.payloadEditOrderDetails(response);
      });
    },
    addproductsby() {
      if (this.currentIembyname != null) {
        this.addProduct(this.currentIembyname);
        this.currentIembyname = null;
      }
      if (this.currentIembyId != null) {
        this.addProduct(this.currentIembyId);
        this.currentIembyId = null;
      }
    },
    addservicesby() {
      if (this.currentItemServiceByName != null) {
        this.addService(this.currentItemServiceByName);
        this.currentItemServiceByName = null;
      }
      if (this.currentItemServiceById != null) {
        this.addService(this.currentItemServiceById);
        this.currentItemServiceById = null;
      }
    },

    closeAlert() {
      this.alert.show = false;

      if (this.alert.options == "insert") {
        this.$store.dispatch("reloadData", "purchase");
        this.$store.commit("providersData/setOrderDetails", null);
        this.$store.commit("providersData/setOrder", null);
        this.$store.commit("providersData/setReloaders", "reloadAddPurchase");

        this.$store.commit("providersData/changeView", "tab-3");
      }

      if (this.alert.options == "updateFinishAndPrintInvoice") {
        this.print2 = {
          show: true,
          data: { ...this.datatoprint },
          closeCallback: () => {
            this.$store.commit("posData/setReloaders", "reloadHistory");
          },
        };

        this.productsToShow = []
      }
    },
    acceptAlert() {
      this.alert.show = false;
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    getProducts() {
      this.search = { ...this.filterProducts };
      this.$API.products
        .getProductsToBillForPurchaseOrders({
          filterProducts: this.filterProducts,
        })
        .then((response) => {
          // console.log("productos");
          // console.log(response);
          this.productsToBill = response;
        })
        .catch((error) => {
          console.log("error de productos");
          console.log(error);
        });
    },
    getAllServices() {
      this.$API.services
        .getAllServicesHistory()
        .then((response) => {
          this.servicesToBill = response;
        })
        .catch((error) => {
          console.log("error de servicios");
          console.log(error);
        })
    },

    addProduct(data) {
      if (!this.productsToShow.find((i) => i.ID_Item == data.ID_Item)) {
        let dataCopy = {...data}
        let ItemPriceQty = 0;
        if(this.globalWarehouse != null && this.globalWarehouse != '') {
          let found = this.warehouses.find((wh) => wh.ID_Warehouse == this.globalWarehouse)
          dataCopy.Warehouse = found.Warehouse
          dataCopy.ID_Warehouse = found.ID_Warehouse
        }

        this.productsToShow.push({
          ...dataCopy,
          ItemNewPrice: 0,
          qty: 0,
          ItemPriceQty: ItemPriceQty,
          IsExento: false,
          IsNoSujeto: false,
          iva: this.header.purchaseordertype == 'CCF' ? true : false,
        });
      }
    },

    addService(data) {
      if (!this.productsToShow.find((i) => i.ID_Item == data.CodeService)) {
        let ItemPriceQty = 0;
        this.productsToShow.push({
          AditionalType: null,
          ID: null,
          ID_AccountingCategory: null,
          ID_AccountingLine: null,
          ID_AccountingSubCategory: null,
          ID_Branch: data.ID_Branch,
          ID_Business: data.ID_Business,
          ID_Item: data.CodeService,
          ID_Provider: this.data.ID_Provider,
          ID_Warehouse: null,
          IdAccountingFamily: null,
          IsGlobalBranch: data.IsGlobalBranch,
          ItemBarCode: null,
          ItemComercialName: data.Name || data.NameService,
          ItemConversionFactor: null,
          ItemDescription: data.Name || data.NameService,
          ItemImagePath: null,
          ItemLote: null,
          ItemMeasureUnit: null,
          ItemName: data.Name || data.NameService,
          ItemNote: "",
          ItemPrice: data.Price,
          ItemType: "service",
          SystemDateInsert: data.SystemDateInsert,
          UnitsPackaging: null,
          Warehouse: null,
          ItemNewPrice: 0,
          qty: 0,
          ItemPriceQty: ItemPriceQty,
          IsExento: false,
          IsNoSujeto: false,
        })
      }
    },

    removeRow(data) {
      this.productsToShow.splice(data.rowIndex, 1);
    },
    Preview_image() {
      this.url = URL.createObjectURL(this.image);
    },
    async getPurchaseId() {
      await this.$API.orders.getOrderId().then((response) => {
        this.orderid = response;
        this.header.orderid = response;
      });
    },
    resetData() {
      this.$store.commit("providersData/setOrderDetails", null);
      this.$store.commit("providersData/setOrder", null);
      this.$store.commit("providersData/setReloaders", "reloadAddPurchase");
    },
    addPurchaseOrder(statusPurchaseOrde) {
      try {
        
        if (this.checkImageSize()) return;
  
        //find a number negative o nan
        let negative = false;
        this.productsToShow.forEach((element) => {
          if (
            element.qty <= 0 ||
            element.qty == null ||
            element.qty == undefined
          ) {
            negative = true;
          }
        });
        if (negative) {
          this.showAlert(
            "warning",
            "Advertencia",
            "La cantidad de los productos no puede ser menor o igual a 0"
          );
          return;
        }
  
        statusPurchaseOrde == "notprocess"
          ? (this.header.purchaseorderpendingpayment = true)
          : (this.header.purchaseorderpendingpayment = false);
        statusPurchaseOrde == "process"
          ? (this.header.orderprocessed = true)
          : (this.header.orderprocessed = false);
  
        let errors = 0;
  
        if (statusPurchaseOrde == "process") {
          this.header.purchaselegalinvoicenumber == "No hay correlativo" ||
          this.header.purchaselegalinvoicenumber == ""
            ? (errors += 1)
            : "";
  
          this.header.paymentMethod ? "" : (errors += 1);
          this.header.invoiceStatus ? "" : (errors += 1);
          this.header.documenttype ? "" : (errors += 1);
  
          if (this.header.invoiceStatus == "Crédito") {
            this.header.days ? "" : (errors += 1);
          } else {
            this.header.days = null;
          }
        }
  
        if (errors > 0) {
          this.showAlert(
            "warning",
            "Advertencia",
            "Debe llenar todos los campos obligatorios"
          );
          return;
        }
        this.loading = true;

        let ivaGeneral = 0.00;

        if (this.header.purchaseordertype != 'CCF') {
          ivaGeneral = parseFloat(parseFloat(this.sumObject.subTotal) - (parseFloat(this.sumObject.subTotal) / 1.13)).toFixed(4)
        }
        else {
          ivaGeneral = parseFloat(this.sumObject.iva).toFixed(4)
        }
  
        let header = {
          ...this.header,
          subtotal: this.header.purchaseordertype != "CCF" ? parseFloat(this.sumObject.subTotal / 1.13).toFixed(4) : parseFloat(this.sumObject.subTotal).toFixed(4),
          iva: ivaGeneral,
          incometax: this.sumObject.nontaxable,
          ivaret: parseFloat(this.sumObject.ivaret).toFixed(4),
          total: parseFloat(this.sumObject.total).toFixed(4),
          purchaselegalinvoicenumber: this.header.purchaselegalinvoicenumber,
          purchaseorderimage: this.url,
          ivaperc: parseFloat(this.sumObject.ivaperc).toFixed(4),
          exento: parseFloat(this.sumObject.exento).toFixed(4),
          nosujeto: parseFloat(this.sumObject.nosujeto).toFixed(4),
        };
  
        let items = this.productsToShow.map((i) => {
          let total_price = parseFloat(i.ItemNewPrice) * parseInt(i.qty);
          let unitPrice = parseFloat(i.ItemNewPrice)
          let totalUnitIva = this.header.purchaseordertype == "CCF" ? parseFloat(unitPrice * 0.13) : parseFloat(unitPrice - (unitPrice / 1.13))
          let totalUnitCost = this.header.purchaseordertype == "CCF" ? parseFloat(unitPrice) : parseFloat(unitPrice / 1.13)
          let totalIva = this.header.purchaseordertype == "CCF" ? parseFloat(total_price * 0.13) : parseFloat(total_price - (total_price / 1.13))
          let totalCCF = parseFloat(total_price + totalIva)

          return {
            itemquantity: i.qty,
            iditem: i.ID_Item,
            itemcurrency: i.ItemCurrency || "$",
            itemunitcost: this.header.purchaseordertype == "CCF" ? parseFloat(i.ItemNewPrice).toFixed(4) : parseFloat(totalUnitCost).toFixed(4),
            itemincometax: 0, //Esto es inservible - i.nontaxable ? parseFloat(total_price).toFixed(4) : 0,
            itemiva: parseFloat(totalUnitIva).toFixed(4),
            itemrettax: 0, //Esto es inservible - parseFloat(ivaret).toFixed(4),
            itemtotalcost: (i.IsExento == false && i.IsNoSujeto == false) ?
              ( this.header.purchaseordertype == "CCF" ? parseFloat(totalCCF).toFixed(4) : parseFloat(total_price).toFixed(4) )
              : 0,
            itemtype: i.ItemType,
            warehouse: i.ID_Warehouse,
            expirationdate: i.ExpirationDate || "",
            iditembatch: i.ID_ItemBatch || "",
            idbranch: header.idbranch,
            isglobalbranch: i.IsGlobalBranch,
            idbusiness: i.ID_Business,
            itemmeasureunit: i.ItemMeasureUnit,
            barcode: i.BarCode,
            itemexento: i.IsExento ? parseFloat(total_price / 1.13).toFixed(4) : 0,
            itemnosujeto: i.IsNoSujeto ? parseFloat(total_price / 1.13).toFixed(4) : 0,
            
          };
        });
  
        const formdata = new FormData();
        
        formdata.append("file", this.image);
        formdata.append("header", JSON.stringify(header));
        formdata.append("items", JSON.stringify(items));
        this.$API.orders
          .addOrder(formdata)
          .then((response) => {
            this.loading = false;
            this.showAlert(
              "success",
              "Orden de compra",
              response.message,
              "insert"
            );
            
            this.$store.commit("setChanguePriceList", true);
          })
          .catch(() => {
            this.loading = false;
            this.showAlert(
              "danger",
              "Orden de compra",
              "Error al crear la orden de compra"
            );
          });
      } catch (error) {
        this.loading = false;
        this.showAlert(
          "danger",
          "Orden de compra",
          "Error al crear la orden de compra"
        );
      }
    },
    updateWarehouse(data, value) {
      const id = data.ID_Item;
      const warehouse = this.warehouses.find((i) => i.Warehouse === value);
      this.productsToShow = this.productsToShow.map((item) => {
        if (item.ID_Item === id) {
          item.ID_Warehouse = warehouse.ID_Warehouse;
        }
        return item;
      });
     
      
    },
    async addFnsPurchaseOrder() {
      if (
        this.header.paymentMethod == undefined ||
        this.header.paymentMethod == null ||
        this.header.paymentMethod == ""
      ) {
        this.showAlert(
          "warning",
          "Advertencia",
          "El método de pago de la factura del proveedor es requerido."
        );
        return;
      }

      let header = {
        ...this.header,
        subtotal: parseFloat(this.sumObject.subTotal).toFixed(4),
        iva: parseFloat(this.sumObject.iva).toFixed(4),
        incometax: this.sumObject.nontaxable,
        ivaret: parseFloat(this.sumObject.ivaret).toFixed(4),
        total: parseFloat(this.sumObject.total).toFixed(4),
        purchaselegalinvoicenumber: this.header.purchaselegalinvoicenumber,
        purchaseorderimage: this.url,
        exento: parseFloat(this.sumObject.exento).toFixed(4),
        nosujeto: parseFloat(this.sumObject.nosujeto).toFixed(4),
      };

      let obj = {
        invoicedate: header.purchaseorderdate,
        invoicetype: header.purchaseordertype,
        idinvoice: null,
        invoiceidinvoice: "No hay correlativo",
        invoiceisnontaxable:
          header.purchaseorderisnontaxable == "" ||
          header.purchaseorderisnontaxable == null
            ? 0
            : 1,
        invoiceisnonsuj: 0,
        istemplate: 0,
        isquote: 0,
        timesexecute: 0,
        idbusiness: header.idbusiness,
        idbranch: header.idbranch,
        idcustomer: header.provider,
        firstname: null,
        lastname: null,
        phonenumber: null,
        mobilenumber: null,
        email: null,
        gender: null,
        age: null,
        dateofbirth: null,
        address: null,
        city: header.purchaseordercity,
        state: header.purchaseorderstate,
        country: header.purchaseordercountry,
        notes: header.purchaseordernotes,
        status: 1,
        sendemail: 0,
        sendsms: 0,
        sendwhatsapp: 0,
        sendpushup: 0,
        isa_business: true,
        businessname: header.purchaseordername,
        businessphone: header.purchaseorderphone,
        businessmobilenumber: header.purchaseordermobile,
        businessemail: header.purchaseorderemail,
        businesscontactname: header.purchaseordercontactname,
        businesscontactphone: header.purchaseordercontactphone,
        businesscontactmobile: header.purchaseordercontactmobile,
        businesscontactemail: header.purchaseordercontactemail,
        businesswebsite: null,
        sendemailbusinessemail: 0,
        sendsmsbusinessmobile: 0,
        sendwhatsappbusinessmobile: 0,
        invoicename: header.purchaseordername,
        invoiceaddress: header.purchaseorderaddress,
        invoicenrc: header.purchaseordernrc,
        invoicenit: header.purchaseordernit,
        dui: null,
        invoicesector: header.purchaseordersector,
        invoiceentity: header.purchaseorderentity,
        invoicetaxpayersize: header.purchaseordertaxpayersize,
        invoiceinfo: null,
        iduser_insert: null,
        iduser_update: null,
        department: header.purchaseorderstate,
        municipality: header.purchaseordercity,
        codactividad: null,
        descactividad: null,
        iddocumenttype: 2,
        prefijo: null,
        pricelistname: null,
        limitcredit: 0,
        itemtext: header.purchaseordername,
        invoicepaymenttype: header.paymentMethod,
        invoicestatusdescription: header.invoiceStatus,
        invoicecreditday: header.days,
        invoicestatus: 1,
        invoicedescunosuj: 0,
        invoicedescuexenta: 0,
        invoicedescugravada: 0,
        invoiceporcentajedescuento: 0,
        invoicetotaldescu: 0,
        invoicesubtotalventas: parseFloat(
          parseFloat(header.subtotal).toFixed(2)
        ),
        invoicesubtotal: parseFloat(parseFloat(header.subtotal).toFixed(2)),
        invoicemontototaloperacion: parseFloat(
          parseFloat(header.subtotal).toFixed(2)
        ),
        invoicetotalexenta: header.exento,
        invoicetotalnosuj: header.nosujeto,
        invoicetotalgravada: parseFloat(parseFloat(header.subtotal).toFixed(2)),
        invoicetotalpagar: parseFloat(parseFloat(header.total).toFixed(2)),
        invoicetotaliva: 0,
        invoiceivarete1: 0,
        invoiceivaperce1: 0,
        invoicetotalletras: numberToText(header.total),
        invoicedui: null,
        invoicereterenta: parseFloat(parseFloat(header.ivaret).toFixed(2)),
        codpointsale: localStorage.getItem("codPointSale"),
      };

      let payment = {};
      payment.typePayment = this.paymentTypes.find(
        (element) => element.Valores === this.header.paymentMethod
      ).Codigo;
      payment.descriptionPayment = this.header.paymentMethod;
      payment.totalPayment = obj.invoicetotalpagar;
      if (
        this.header.paymentMethod != "Billetes y monedas" &&
        this.header.nreference != undefined
      ) {
        payment.reference = this.header.nreference;
      } else {
        payment.reference = null;
      }

      let payments = [];
      payments.push(payment);

      obj.payments = payments;

      let items = this.productsToShow.map((i) => {
        let total_price = parseFloat(i.ItemNewPrice) * parseInt(i.qty);
        let iva = parseFloat(total_price - total_price / 1.13);
        //let ivaret = 0;

        return {
          idbranch: i.ID_Branch,
          idbusiness: i.ID_Business,
          iditem: i.ID_Item,
          itemtype: i.ItemType,
          itemquantity: i.qty,
          itemmeasureunit: i.ItemMeasureUnit,
          itemdescription: i.ItemName,
          itemunitcost: parseFloat(parseFloat(i.ItemNewPrice).toFixed(4)),
          itemdiscount: 0,
          itemdiscountpercent: 0,
          itemnosuj: i.IsExento ? parseFloat(total_price / 1.13).toFixed(4) : 0,
          itemexenta: i.IsNoSujeto ? parseFloat(total_price / 1.13).toFixed(4) : 0,
          itemgravada: (i.IsExento == false && i.IsNoSujeto == false) ? parseFloat(parseFloat(total_price).toFixed(4)) : 0,
          itempsv: 30,
          itemnogravada: 0,
          itemiva: iva,
          itemcurrency: i.ItemCurrency || "$",
          expirationdate: i.ExpirationDate || "",
          itembatch: i.ID_ItemBatch || "",
          warehouse: i.ID_Warehouse,
          barcode: i.BarCode || "",
        };
      });
      this.loading = true;

      this.$API.invoices
        .addInvoice({
          header: obj,
          items: items,
          user: JSON.parse(localStorage.getItem("user")).id,
          invalid: null,
          ndornc: null,
        })
        .then(async (response) => {
          this.loading = false;

          this.datatoprint.dteJson = response.billingDteJsonHeader;
          if (response.estado == "PROCESADO") {
            let mensaje = "";
            mensaje = "Se ha realizado y procesado la factura.<br/><br/>";
            mensaje += "Estado: " + response.estado + "<br/>";
            mensaje +=
              "Mensaje del DTE: <br/> - " + response.descripcionMsg + "<br/>";
            mensaje += "Observaciones DTE: <br/>";
            if (response.observaciones.length == 0) {
              mensaje += "- Ninguna observación <br/>";
            } else {
              for (let i = 0; i < response.observaciones.length; i++) {
                mensaje += "- " + response.observaciones[i] + "<br/>";
              }
            }

            this.datatoprint.header = await this.$API.invoices.getInvoice(
              response.billingDteJsonHeader.ID_Invoice
            );
            this.datatoprint.body = await this.$API.invoices.getAllInvoiceItems(
              response.billingDteJsonHeader.ID_Invoice
            );
            //console.log("VEAMOS", this.datatoprint)
            this.showAlert(
              "successHtml",
              "Factura generada",
              mensaje,
              "updateFinishAndPrintInvoice"
            );
          } else if (response.estado == "CONTINGENCIA") {
            let mensaje = "";
            mensaje = `La factura se ha guardado en estado de contingencia.<br/><br/>`;
            mensaje += "Estado: " + response.estado + "<br/>";
            mensaje +=
              "Error del DTE: <br/> - " +
              response.status +
              ", " +
              response.error +
              "<br/>";
            this.datatoprint.header = await this.$API.invoices.getInvoice(
              response.billingDteJsonHeader.ID_Invoice
            );
            this.datatoprint.body = await this.$API.invoices.getAllInvoiceItems(
              response.billingDteJsonHeader.ID_Invoice
            );
            this.showAlert(
              "successHtml",
              "Factura generada",
              mensaje,
              "updateFinishAndPrintInvoice"
            );
          } else {
            let mensaje =
              "Se ha rechazado la factura, tomar en cuenta las observaciones del Ministerio de Hacienda y realizarla de nuevo.<br/><br/>";
            mensaje += "Estado: " + response.estado + "<br/>";
            mensaje +=
              "Mensaje del DTE: <br/> - " + response.descripcionMsg + "<br/>";
            mensaje += "Observaciones DTE: <br/>";
            if (response.observaciones.length == 0) {
              mensaje += "- Ninguna observación <br/>";
            } else {
              for (let i = 0; i < response.observaciones.length; i++) {
                mensaje += "- " + response.observaciones[i] + "<br/>";
              }
            }
            this.showAlert("successHtml", "Factura rechazada", mensaje);
          }
        });
    },

    onEditorChange(e) {
      this.header.purchaseorderinternalnote = e.html;
      if (this.header.purchaseorderinternalnote != "") {
        this.showBgcolorNotes = "tabsContent";
      } else if (
        this.header.purchaseorderinternalnote == "" ||
        this.header.purchaseorderinternalnote == "<p><br></p>"
      ) {
        this.showBgcolorNotes = "tabs";
      } else {
        this.showBgcolorNotes = "tabs";
      }
    },

    async payloadEditOrderDetails(warh) {
      let product = [];

      this.orderDetails.forEach(function (element) {
        for (let i = 0; i < warh.length; i++) {
          if (warh[i].ID_Warehouse == element.Warehouse) {
            element.WarehouseName = warh[i].Warehouse;
          }
        }

        let item = {
          ItemNewPrice: element.ItemUnitCost,
          qty: element.ItemQuantity,
          ID_Item: element.ID_Item,
          ItemCurrency: element.ItemCurrency,
          nontaxable: element.ItemIncomeTax,
          ItemType: element.ItemItemType,
          ID_Warehouse: element.Warehouse,
          Warehouse: element.WarehouseName,
          ExpirationDate: element.ExpirationDate,
          ID_ItemBatch: element.ItemLote,
          ID_Branch: element.ID_Branch,
          IsGlobalBranch: element.IsGlobalBranch,
          ID_Business: element.ID_Business,
          ItemMeasureUnit: element.ItemMeasureUnit,
          ItemPriceQty: element.ItemTotalCost - element.ItemIVA,
          ItemName: element.ItemName,
          BarCode: element.BarCode,
          IsExento: (element.ItemExento > 0) ? true : false,
          IsNoSujeto: (element.ItemNoSujeto > 0) ? true : false,
        };
        product.push(item);
      });

      this.productsToShow = product;

      await Object.keys(this.order).map((el) => {
        if (el == "ID_PurchaseOrder") {
          this.header.orderid = this.order[el];
          this.orderid = this.order[el];
        }

        if (el == "PurchaseOrderImage") {
          this.url = this.order[el];
          this.Preview_image();
        }

        if (el == "PurchaseOrderDate") {
          this.purchaseorderdate = this.order[el];
          this.header.purchaseorderdate = this.order[el];
        }

        if (el == "PurchaseInvoiceDate") {
          this.purchaseinvoicedate = this.order[el];
          this.header.purchaseinvoicedate = this.order[el];
        }

        if (el == "PurchaseOrderType") {
          this.header.purchaseordertype = this.order[el];
        }

        if (el == "ID_PurchaseLegalInvoiceNumber") {
          this.header.purchaselegalinvoicenumber = this.order[el];
        }

        if (el == "PurchaseOrderInternalNote") {
          this.header.purchaseorderinternalnote = this.order[el];
        }

        if (el == "PurchaseOrderSubTotal") {
          this.sumObject.subTotal = this.order[el];
        }

        if (el == "PurchaseOrderTotalIVA") {
          this.sumObject.iva = this.order[el];
        }

        if (el == "PurchaseOrderTotalIncomeTax") {
          this.sumObject.nontaxable = this.order[el];
        }

        if (el == "PurchaseOrderTotal") {
          this.sumObject.total = this.order[el];
        }
      });
    },
    addByBarCode() {
      if (
        !this.productsToShow.find(
          (i) => i.ID_Item == this.barCodeToFind.ID_Item
        )
      ) {
        if (this.barCodeToFind) {
          this.productsToShow.push({
            ...this.barCodeToFind,
            ItemNewPrice: 0,
            qty: 0,
            ItemPriceQty: 0,
            IsExento: false,
            IsNoSujeto: false,
          });
        }
      }
    },
    handleBarCodEnterKey() {
      this.getBarCode(this.data.ID_Provider, this.selectedBarCode);
    },

    handleItemCodeEnterKey(itemcodeValue) {
      if (!this.productsToShow.find((i) => i.ID_Item == itemcodeValue)) {
        const data = this.productsToBill.find(
          (j) => j.ID_Item === itemcodeValue
        );

        if (data) {
          this.productsToShow.push({
            ...data,
            ItemNewPrice: 0,
            qty: 0,
            ItemPriceQty: 0,
            IsExento: false,
            IsNoSujeto: false,
          });
        }
      }
    },

    handleCheckboxIva(checked) {
      if (checked) {
        this.header.purchaseordertype = 'CCF'
        this.addValidToTypeInvoice(false)
        this.productsToShow.forEach((item) => {
          Vue.set(item, "iva", true);
        });
      } else {
        this.header.purchaseordertype = 'FCF'
        this.addValidToTypeInvoice(false)
        this.productsToShow.forEach((item) => {
          Vue.set(item, "iva", false);
        });
      }
    },
    filterHandlerProducts(response) {
      this.productsToBill = response;
    },
    filterHandlerServices(response) {
      this.servicesToBill = response;
    },
    maxFileSize(event) {
      const allowedSize = 2 * 1024 * 1024; // 2 MB in bytes
      const fileSize = event.size;

      if (fileSize > allowedSize) {
        this.validImageSize = false;
        return `El tamaño del archivo debe ser menor a 2 Mb`;
      }

      this.validImageSize = true;
      return true;
    },
    checkImageSize() {
      if (this.validImageSize == false) {
        this.showAlert(
          "danger",
          "Error",
          "El tamaño de la imagen debe ser menor a 2 Mb"
        );
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;
  padding: 0.5rem;
  border-radius: 15px;
  margin: 0.5rem;
}

.input-group.mb-3 {
  text-align: center;
}

.input-group-prepend {
  text-align: left;
}

input.form-control {
  background: #d6eaf3;
  border: 1px solid #1976d2;
  text-align: center;
  max-width: 80px;
  height: 30px;
  border-radius: 20px;
}

.filter-btn {
  position: absolute;
  top: 60px;
  left: 30px;
  z-index: 9;
}

.p-relative {
  position: relative;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
  margin: 0;
  padding: 0;
}

.btn-add {
  background-color: #3498db !important;
  color: white;
}

.btn-json {
  background-color: #3498db !important;
  color: white;
  width: 100% !important;
}

.scroll-bar {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}

/* width */
.scroll-bar::-webkit-scrollbar {
  width: 10px;
}

.btnblack {
  background-color: rgba(60, 60, 60, 1) !important;
  color: white;
}

/* Track */
.scroll-bar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.scroll-bar::-webkit-scrollbar-thumb {
  background: #262626;
  border-radius: 10px;
}

.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}

.tabsContent:hover {
  background-color: #ffb266;
  cursor: pointer;
}

.tabsContent {
  width: 100%;
  padding: 10px;
  background-color: #f29d35;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.custom-select {
  display: inline-block;
  position: relative;
  font-family: Arial;
  font-size: 14px;
  color: #333;
  background-color: #fff;

  width: 100%;
  box-sizing: border-box;
}

.tabs {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.navbar {
  display: flex;
  height: 4.5rem;
  background: #41464c;
  color: white;
  align-self: flex-start;
  z-index: 100;
  position: sticky;
  margin-left: 2.5%;
  margin-right: 2.5%;
  justify-content: space-around;
}

.btnav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 50%;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}

@media screen and (max-device-width: 1024px), screen and (max-width: 1024px) {
  .navbar {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    height: 8rem;
    text-align: center;
  }

  .btnav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .input-group-prepend {
    text-align: center;
  }

  .widthDate .v-input__slot {
    width: 150px;
  }

  .tabs:hover {
    background-color: rgba(80, 80, 80, 1);
    cursor: pointer;
  }
}

.tabs {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.v-application .mb-2 {
  margin-right: 10px !important;
}

.v-application .mb-3 {
  margin-right: 50px !important;
}
</style>

<style>
.scroll-fixing-purchase .dx-overlay-content {
  left: -163% !important;
}

.invoiceResponsiveSummary {
  visibility: hidden;
}

@media only screen and (max-width: 1392px) {
  .invoiceResponsiveSummary {
    background: #41464c !important;
    color: white;
    bottom: 0;
    left: 280px;
    visibility: visible;
  }

  .toHide {
    display: none !important;
  }

}
@media only screen and (max-width: 440px) {
btn-size{
  font-size: 20px;
}
}

</style>
